
@import '~antd/dist/antd.less';

h1 {
  font-family: 'Poppins'; 
  text-align: center; 
  font-size: 32px;
  @media screen and (min-width: 580px) {
    font-size: 42px;
  }
  color: #525252;
}

h2 {
  font-family: 'Montserrat';
  text-align: center; 
  font-size: 30px;
  color: #86b59f;
}

h3 {
  color: #808080;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}

h4 {
  font-family: 'Montserrat';
  text-align: left; 
  font-size: 18px;
  color: #86b59f;
}

strong {
  font-family: 'Montserrat-bold';
  color: rgba(0, 0, 0, 0.65);
}

p, a, i, ul {
  font-size: 16px;
}

.ant-alert-message {
  color: #b35e56;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% {  transform: rotate(359deg); }
}
#ring {
  animation: spin 2s linear infinite;

}

.ant-divider-vertical {
  border-color:  #bfbfbf;
}

.ant-timeline-item-head {
  background: transparent;
}

.ant-card {
  background-color: transparent;
  border-color:  #e3e3e3;
}

.ant-divider-horizontal {
  border-color:  #e3e3e3;
}

.card-with-shadow {
  box-shadow: 0 1px 2px rgba(0,0,0,0.15);
  transition: all 0.3s ease-in-out;
}

.card-with-shadow-wrong {
  box-shadow: 0 1px 2px rgba(0,0,0,0.15);
  transition: all 0.3s ease-in-out;
  position: 'relative';
  min-height: 150px;
}

/* Pre-render the bigger shadow, but hide it */
.card-with-click::after {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  transition: opacity 0.3s ease-in-out;
}

/* Transition to showing the bigger shadow on hover */
.card-with-click:hover::after {
  opacity: 1;
  cursor: pointer;
}

.card-with-click:hover {
  transform: scale(1.2, 1.2);
  cursor: pointer;
}

.confetti {
  position: absolute !important;
  top: 50%;
  left: 50%;
}

.confetti-final {
  position: absolute !important;
  top: 0%;
  left: 50%;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@primary-color: #86b59f;@link-color: #167335;@border-color-base: #167335;