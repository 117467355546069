body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'NothingYouCouldDo';
  src: local('NothingYouCouldDo'), url(./fonts/NothingYouCouldDo-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'AmaticSC';
  src: local('AmaticSC'), url(./fonts/AmaticSC-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Pacifico';
  src: local('Pacifico'), url(./fonts/Pacifico-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-bold';
  src: local('Montserrat-bold'), url(./fonts/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Meddon';
  src: local('Meddon'), url(./fonts/Meddon-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-light';
  src: local('Poppins'), url(./fonts/Poppins-Light.ttf) format('truetype');
}

